<template lang="pug">
.my-6
  .flex.flex-wrap.max-w-md.mx-auto
    .flex-auto
      input(type="text" placeholder="Filename..." class="w-full px-4 py-2 rounded-lg focus:outline-none dark:bg-gray-800 dark:placeholder-gray-700" v-model="form.filename")
    .mt-2.w-full
      textarea(placeholder="Description..." class="w-full px-4 py-2 rounded-lg focus:outline-none dark:bg-gray-800 dark:placeholder-gray-700" v-model="form.description")
  hr.my-6(class="dark:border-gray-800")
  .my-6.text-center
    button.mr-2(class="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded inline-flex" @click="destroy()")
      span.mr-2
        svg(viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round")
          polyline(points="3 6 5 6 21 6")
          path(d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2")
          line(x1="10" y1="11" x2="10" y2="17")
          line(x1="14" y1="11" x2="14" y2="17")
      span.leading-tight Delete
    button.mr-2(class="bg-purple-500 hover:bg-purple-700 text-white py-2 px-4 rounded inline-flex" @click="update()")
      span.mr-2
        svg(viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round")
          path(d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z")
          polyline(points="17 21 17 13 7 13 7 21")
          polyline(points="7 3 7 8 15 8")
      span.leading-tight Update
    button(class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded inline-flex" @click="download()")
      span.mr-2
        svg(viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round")
          path(d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4")
          polyline(points="7 10 12 15 17 10")
          line(x1="12" y1="15" x2="12" y2="3")
      span.leading-tight Download
  //.my-6
    button.mr-2.mb-2(class="bg-gray-800 text-gray-400 py-1 px-2 rounded leading-tight") Disable Shadow
    button.mr-2.mb-2(class="bg-gray-800 text-gray-400 py-1 px-2 rounded leading-tight") Black Sky
    button.mr-2.mb-2(class="bg-gray-800 text-gray-400 py-1 px-2 rounded leading-tight") No Texture
    button.mr-2.mb-2(class="bg-gray-800 text-gray-400 py-1 px-2 rounded leading-tight") White Character
    button.mr-2.mb-2(class="bg-gray-800 text-gray-400 py-1 px-2 rounded leading-tight") Remove Unused Feature
  ul
    li.mb-2(v-for="(value, key) in configs['[UserCustom DeviceProfile]']")
      .inline-flex.font-mono
        span.mr-2 {{ key }}
        input.mr-2(type="text" class="w-20 px-2 rounded-lg focus:outline-none dark:bg-gray-800 dark:placeholder-gray-700" v-model="configs['[UserCustom DeviceProfile]'][key]")
        button.mr-2.text-red-500(@click="deleteConfig(key)")
          svg(viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round")
            polyline(points="3 6 5 6 21 6")
            path(d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2")
            line(x1="10" y1="11" x2="10" y2="17")
            line(x1="14" y1="11" x2="14" y2="17")
    li.my-3.pt-3.border-t(class="dark:border-gray-800")
      .flex.flex-wrap.items-end.-mx-3
        .flex-1
          .px-3
            label.text-sm Key
            div
              input(type="text" class="w-full px-2 rounded focus:outline-none dark:bg-gray-800 dark:placeholder-gray-700" v-model="configForm.key")
        .flex-1
          .px-3
            label.text-sm Value
            div
              input(type="text" class="w-full px-2 rounded focus:outline-none dark:bg-gray-800 dark:placeholder-gray-700" v-model="configForm.value")
        .flex-none
          .px-3
            button.block(class="bg-gray-800 text-gray-400 py-1 px-2 rounded leading-tight" @click="addConfig()") Add
</template>

<script>
export default {
  name: 'active-sav',
  data() {
    return {
      form: {
        filename: '',
        description: '',
      },
      configForm: {
        key: '',
        value: '',
      },
      configs: {}
    }
  },
  computed: {
    file() {
      return this.$store.getters['storage/file'](this.$route.params.filename)
    },
  },
  mounted() {
    this.setForm()
    this.parseConfigs()
  },
  methods: {
    setForm() {
      this.form.filename = this.file.filename
      this.form.description = this.file.description
    },
    parseConfigs() {
      let lines = this.file.content.split("\n")
      let configName = null
      for (const _line of lines) {
        let line = _line.trim()
        if (!line) continue

        if(/^\[(.*)\]$/.test(line)) {
          configName = line
          this.configs[configName] = {}
          continue
        }

        if (configName === null) continue

        if (line.includes('+CVars=')) {
          let config = this.$usercustom.decrypt(line.replace('+CVars=', ''))
          let [key, value] = config.split('=')
          this.configs[configName][key] = value
        }
      }
    },
    encryptContent() {
      let content = ''

      for (const configName in this.configs) {
        content += configName + "\n"
        for (const key in this.configs[configName]) {
          const value = this.configs[configName][key]
          const encrypted = this.$usercustom.encrypt(`${key}=${value}`)
          const line = `+CVars=${encrypted}\n`
          content += line
        }
        content += "\n"
      }

      return content
    },
    destroy() {
      this.$store.commit('storage/remove', this.$route.params.filename)
      this.$router.push('/')
    },
    download() {
      const el = document.createElement('a')
      const content = this.encryptContent()

      el.style.display = 'none'
      el.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content))
      el.setAttribute('download', this.form.filename)
      document.body.appendChild(el)
      el.click()
      document.body.removeChild(el)
    },
    addConfig() {
      this.configs['[UserCustom DeviceProfile]'][this.configForm.key] = this.configForm.value
      this.configForm.key = ''
      this.configForm.value = ''
    },
    deleteConfig(key) {
      delete this.configs['[UserCustom DeviceProfile]'][key]
    },
    update() {
      const content = this.encryptContent()

      this.$store.commit('storage/update', {
        filename: this.$route.params.filename,
        data: {
          filename: this.form.filename,
          description: this.form.description,
          content
        }
      })

      this.$router.push(`/usercustom/${this.form.filename}`)
    }
  }
}
</script>
